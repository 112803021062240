









































import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";
import moment from "moment";

@Component
export default class EpisodeList extends Vue {
  public list = [];
  public settings: Settings;
  public actualPage: number;
  public totalItems: number;
  public limit: number;

  public constructor() {
    super();
    this.list = [];
    this.actualPage = 1;
    this.totalItems = 1;
    this.limit = 12;
    this.settings = new Settings();
  }

  public loadEpisodes(): void {
    this.axios.get(this.settings.settings.host + '/api/episodes',{
      params: {
        page: this.actualPage.toString(),
        'order[date_add]': 'desc'
      },
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.totalItems = response.data['hydra:totalItems'];
      this.list = response.data['hydra:member'];
    });
  }

  public transformDate(date) {
    return moment(date).lang("pl").format('LLL');
  }


  public deleteEpisode(idx, id): void {
    this.axios.delete(this.settings.settings.host + '/api/episodes/' + id,{
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.$notify({
        group: 'notify',
        type: 'success',
        text: 'Odcinek został usuniety'
      });
      this.loadEpisodes();
    });
  }

  created(): void {
    this.loadEpisodes();
  }
}
